<template>
  <keep-alive>
    <div id="app">
      <div class="view">
        <img src="@/assets/logo.png">
        <div class="form">
          <van-form @submit="onSubmit">
            <van-field
                v-model="nickName"
                name="nickName"
                label="姓名"
                placeholder="请输入您的姓名"
                :rules="[{ required: true, message: '请输入您的姓名' }]"
            />
            <van-field name="radio" label="性别" :rules="[{ required: true, message: '请选择您的性别' }]">
              <template #input>
                <van-radio-group v-model="sex" direction="horizontal">
                  <van-radio name="1">男</van-radio>
                  <van-radio name="2">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field v-model="age" name="age" type="digit" label="年龄" maxlength="2"
                       :rules="[{ required: true, message: '请输入您的年龄' }]"/>
            <van-field v-model="mobile" name="mobile" type="tel" label="手机号"
                       :rules="[{ required: true, message: '请输入您的手机号' },{ pattern: /\d{11}/, message: '请输入正确手机号' }]"/>
            <van-field
                v-model="vcode"
                name="vcode"
                center
                label="短信验证码"
                :rules="[{ required: true,message: '请输入手机验证码'}]"
            >
              <template #button>
                <van-button class="button" size="small" type="primary" native-type="button" @click="gerVerificationCode"
                            :disabled="clickKey">
                  <span v-if="clickKey">还有{{ num }}秒</span>
                  <span v-else>获取验证码</span>
                </van-button>
              </template>
            </van-field>
            <div style="margin: 16px;">
              <van-button class="button" round block type="info" native-type="submit">立即获取兑换码</van-button>
            </div>
          </van-form>
        </div>
        <div class="role">
          <p>*每人限领一份体验装，不得代领。</p>
          <p>*仅限活动期内有效，数量不限，领完即止。</p>
          <p>*兑换码当天内有效，请尽快于派样机屏幕上输入兑换码，领取体验装。</p>
        </div>
      </div>
    </div>
  </keep-alive>
</template>
<script>
import axios from 'axios';
import {Toast} from 'vant';
import startLocation from "../utils/gps"
export default {
  data() {
    return {
      nickName: '',
      sex: '',
      age: '',
      mobile: '',
      vcode: '',
      clickKey: false,
      num: 60,
      timeOut: null,
      SX: false,
      deviceNo: 99,
      gps:undefined,
    };
  },
  beforeCreate() {
  //  判断 60-2  如果是的话 现在时间和当初存入时间相减 如果相减小于零 则
    let num = localStorage.getItem("num");
    let time = localStorage.getItem("time");
    let SX = localStorage.getItem("SX");
    let mm = new Date()*1;
    let ss = Math.floor((mm*1-time*1)/1000);
    if(ss>1 && JSON.parse(SX)){
      if(ss<num && ss>2){
        localStorage.setItem("num",num-ss)
      }else if(num<ss){
        localStorage.setItem("num",2)
      }
    }
  },
  created() {
    this.change();
    startLocation.then(gps=>{
      this.gps = gps;
    })
  },

  // 监听页面进行刷新和退出时顺带进行的操作
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {

    // 进行退出的操作
    beforeunloadFn(e){
      let time = new Date()*1;
      localStorage.setItem("time",time);
    },

    // 信息填写完成后提交表单
    onSubmit(values) {
      if(this.gps){
        let inf = {
          deviceNo: this.deviceNo,
          longitude: this.gps.lng,
          latitude: this.gps.lat,
          ...values,
        }
        axios.post("https://new-satest-api.mageline.cn//gift/obtain", inf).then(res => {
          switch (res.data.code){
            case 200:
              this.$router.push({path: "/secentView", query: res.data});
              break;
            case -1:
              this.$router.push({path: "/secentView", query: res.data});
              break;
            case -2:
              this.$router.push({path: "/secentView", query: res.data});
              break;
            case -3:
              this.$router.push({path: "/secentView", query: res.data});
              break;
            default:
              Toast(res.data.message)
              break;
          }
        }).catch(err=>{
          Toast(err)
        })
      }else{
        Toast("获取当前位置失败，请打开您的GPS定位,并且重新进入此界面")
      }
    },

    // 获取url ？后面 传递的信息
    change() {
      let url = location.search; //获取url中"?"符后的字串
      let theRequest = new Object();
      this.SX = JSON.parse(localStorage.getItem('SX'))
      if (this.SX) {
        this.half();
      }
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
        this.deviceNo = theRequest.deviceNo;
      }
    },

    // 判断是否刷新后重新进入
    half() {
      this.num = JSON.parse(localStorage.getItem('num')) || 60;
      this.clickKey = JSON.parse(localStorage.getItem('clickKey')) || false;
      this.clickGetNote();
    },

    //计时器
    time() {
      localStorage.setItem("SX", 'true');
      this.SX = JSON.parse(localStorage.getItem('SX'));
      this.timeOut = setInterval(() => {
        if (this.num === 1) {
          localStorage.setItem("SX", 'false');
          this.SX = JSON.parse(localStorage.getItem('SX'));
          clearInterval(this.timeOut);
          this.num = 60;
          this.clickKey = false;
        }
        localStorage.setItem('num', this.num);
        this.num--;
      }, 1000)
    },

    // 验证码操作
    clickGetNote() {
      if (!this.clickKey) {
        //  正在倒计时
        this.clickKey = true;
        localStorage.setItem('clickKey', this.clickKey);
        this.time();
      } else if (this.num * 1 !== 60) {
        // 倒计时 中途 刷新重新进入
        this.time();
      }
    },

    //点击获取验证码
    gerVerificationCode() {
      // 先检测手机号是否输入 再判断是否正确  两者都通过后调用接口 接口调用完成后 发送验证码变成还有60
      if (this.mobile !== "" && /\d{11}/.test(this.mobile)) {
        axios.post("https://new-satest-api.mageline.cn/gift/sendCode", {deviceNo:this.deviceNo , mobile: this.mobile}).then(res => {
          if (res.data.success) {
            this.clickGetNote();
          } else {
            Toast(res.data.message)
          }
        })
      } else {
        Toast("请输入手机号");
      }
    },
  }
};
</script>
<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
}
#app{
  position: relative;
}
.view {
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../assets/backgroundImage.png");
  background-repeat: no-repeat; //不重复
  background-size: 100% 100%; // 满屏
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    width: 40%;
    margin: 14% 0 0% 0;
  }

  .form {
    padding: 2% 4%;
    margin: 8% 8% 0 8%;
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
    .van-cell {
      //background: none;
      margin: 1rem 0;
      padding: 6px 16px;
    }
    .button {
      border: 0;
      background: #070D33;
    }
  }

  .role{
    width: 200%;
    color: white;
    transform: scale(0.5,0.5);
    margin-left:20%;
    margin-top: -4%;
    line-height: 0.5rem;
  }
}
</style>
