<template>
  <div class="next">
    <img src="@/assets/logo.png" >
    <div class="inf">
      <div class="success" v-if="data.code === '200'">
        <p style="font-size: 20px;letter-spacing:0.1rem;line-height: 0.1rem">您的兑换码是</p>
        <p class="num">
          <span v-for="(item,index) in data.result" :key="index">{{item}}</span>
        </p>
        <div class="role">
          <p>兑换当天内有效，请尽快于派样机屏幕上</p>
          <p>输入兑换码，领取体验装</p>
        </div>
      </div>
      <p v-if="data.code === '-1'" class="title">活动未开始</p>
      <p v-if="data.code === '-2'" class="title">不在范围内</p>
      <p v-if="data.code === '-3'" class="title">已领完，无库存</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Next',
  data(){
    return {
      data:this.$route.query,
    }
  }
}
</script>
<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
}
.next{
  width: 100%;
  height: 100%;
  background: url("../assets/backgroundImage.png");
  background-repeat: no-repeat;   //不重复
  background-size: 100% 100%;     // 满屏
  display: flex;
  flex-direction: column;
  align-items: center;
  >img{
    width: 40%;
    margin: 14% 0 10% 0;
  }
  .inf{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    background: rgba(0,0,0,0.3);
    padding: 10% 2% ;
    margin: 8%;
    .success{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: white;
      .num{
        display: flex;
        >span{
          text-align: center;
          width: 1.2rem;
          height: 1.2rem;
          padding: 0.2rem;
          border-radius: 0.1rem;
          margin: 0 0.3rem;
          background: white;
          color: black;
        }
      }
      .role{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 0.1rem;
      }
    }
    .title{
      font-size: 1.6rem;
      color:white
    }
  }
}
</style>
