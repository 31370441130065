/*
 * @Autor: A bald boy surnamed Li
 * @Date: 2021-11-23 23:48:51
 * @LastEditors: Dad
 * @LastEditTime: 2021-11-23 23:57:20
 */
import {
  GPS
} from './GPS2AMAP'

let callback = null // promise 的 resolve会赋值给这个函数

// gps 定位成功回调
const onSuccess = (position) => {
  const {
    latitude: lat,
    longitude: lng
  } = position.coords
  const exchange = GPS.gcj_encrypt(Number.parseFloat(lat), Number.parseFloat(lng)) // 对精度要求不高可以省略这步，把上面获取到的经纬度直接传给saveLocation
  console.log('gps定位', `${exchange.lat}-${exchange.lng}`)
  saveLocation(exchange.lat, exchange.lng)
}

// gps 定位失败回调
const onError = () => {
  console.error('gps失败')
//  提示打开jps
}
// 处理获取到的经纬度
const saveLocation = (lat = 0, lng = 0) => {
  lat && (lat = parseFloat(lat).toFixed(6))
  lng && (lng = parseFloat(lng).toFixed(6))
  typeof callback === 'function' && callback({lat, lng})
}

// promise, resolve的第一个参数就是经纬度，

const startLocation = new Promise((resolve, reject) => {
  callback = resolve
  const options = {
    enableHighAccuracy: true,
    maximumAge: 1000,
    timeout: 5000
  }
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options)
  } else {
    saveLocation(0, 0)
    console.error('您的浏览器不支持地理位置定位')
  }
})





export default startLocation